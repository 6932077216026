import React, { useMemo, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import SourceRoutes from "./SourceRoutes";
import keycloak from "../lib/keycloak";
import NotFound from "pages/404";
import { ROLES_KEY } from "constants";
import MainLayout from "layouts/MainLayout";
import Agents from "pages/Dashboard/Agents";
import CreditSummary from "pages/Dashboard/CreditSummary/CreditSummary";
import CreditSummaryList from "pages/Dashboard/CreditSummary/CreditSummaryList";
import CreditsHistoryList from "pages/Dashboard/CreditSummary/CreditsHistoryList";
import UserManagement from "pages/Dashboard/Usermangement/Usermanagment";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { ProgressSpinner } from "primereact/progressspinner";
import UserProfile from "components/DocumentEngine/UserManagement/UserProfile";
import NotAccessible from "./NotAccessible";
import { useSharedState } from "context/ContextWrapper";
import LoginScreen from "pages/Login/Login";
import Authentication from "hooks/authentication";
import { isSettlementDemand } from "constants";

const RouterContent = () => {
  const applicationAccess = ["documentengine"];
  const { keycloak, initialized } = useKeycloak();
  const { authenticated } = keycloak;
  const { sharedState, setSharedState } = useSharedState();
  const navigate = useNavigate();
  const location = useLocation();
  const [isVerifying, setIsVerifying] = useState(true);
  const { emailVerification, updateUserStatus } = Authentication();
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState(null);

  useEffect(() => {
    const isInDocumentEngine = location.pathname.includes('/intelliapp');
    if ((applicationAccess?.includes("documentengine") && isInDocumentEngine) || isSettlementDemand) {
      document.title = "Settlement Demand Ai";
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = "/settlement-demand-favicon.png";
      } else {
        const newFavicon = document.createElement("link");
        newFavicon.rel = "icon";
        newFavicon.href = "/settlement-demand-favicon.png";
        document.head.appendChild(newFavicon);
      }
    } else {
      document.title = "NeIO AI";
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = "/favicon.ico";
      }
    }
  }, [applicationAccess, location.pathname]);

  const roles = useMemo(() => {
    if (authenticated && initialized) {
      const role = keycloak.tokenParsed?.resource_access?.[ROLES_KEY]?.roles;
      const roleString = role?.includes("admin") ? "admin" : "user";
      setSharedState(roleString);
      return role;
    }
    return [];
  }, [authenticated, initialized, keycloak.tokenParsed?.roles]);

  useEffect(() => {
    const verifyEmail = async () => {
      if (authenticated && initialized && keycloak.tokenParsed?.email) {
        try {
          const response = await emailVerification(keycloak.tokenParsed.email);
          if (response?.status === "pending") {
            await updateUserStatus({
              email: keycloak.tokenParsed.email,
              keycloak_id: keycloak.tokenParsed.user_id,
            });
          }
        } catch (error) {
          console.error('Email verification failed:', error);
        } finally {
          setTimeout(() => {
            setIsVerifying(false);
          }, 3000);
        }
      } else {
        setTimeout(() => {
          setIsVerifying(false);
        }, 3000);
      }
    };

    verifyEmail();
  }, [authenticated, initialized]);

  useEffect(() => {
    if (authenticated && initialized) {
      const refreshToken = () => {
        const tokenExpiresIn = keycloak.tokenParsed.exp - Math.ceil(Date.now() / 1000);
        if (tokenExpiresIn < 60) {
          keycloak.updateToken(60).catch(() => {
            console.error('Failed to refresh token');
            navigate('/login');
          });
        }
      };

      const interval = setInterval(refreshToken, 30000);
      setTokenRefreshInterval(interval);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [authenticated, initialized]);

  if (isVerifying) {
    return (
      <div className="flex flex-column justify-content-center h-screen align-items-center">
        <ProgressSpinner />
        <h3 className="mt-3">Verifying your account...</h3>
      </div>
    );
  }

  if (!authenticated && location.pathname !== "/login") {
    navigate("/login");
    return null;
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      {sharedState === "admin" && (
        <>
          <Route path="/" element={<MainLayout />}>
            <Route path="agent" element={<Agents />} />
            <Route path="credits" element={<CreditSummary />} />
            <Route path="credits-list" element={<CreditSummaryList />} />
            <Route path="credits-org-history" element={<CreditsHistoryList />} />
            <Route path="usersmanagement" element={<UserManagement />} />
            <Route path="user/:id" element={<UserProfile />} />
          </Route>
          <Route
            path="/intelliapp/*"
            element={<SourceRoutes applicationAccess={applicationAccess} />}
          />
        </>
      )}
      {sharedState === "user" && (
        <Route
          path="/intelliapp/*"
          element={
            <SourceRoutes applicationAccess={applicationAccess} authenticated={authenticated} />
          }
        />
      )}
      {initialized && authenticated && sharedState !== "admin" && sharedState !== "user" && (
        <Route path="*" element={<NotAccessible />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default function Router() {
  const handleKeyCloakEvent = (event, error) => {
    if (event === "onTokenExpired") {
      keycloak
        .updateToken(60)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token refreshed successfully");
          }
        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
          window.location.href = "/login";
        });
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        checkLoginIframe: false,
        enableLogging: true,
        pkceMethod: false,
        tokenMinValidity: 60,
      }}
      onEvent={handleKeyCloakEvent}
    >
      <BrowserRouter>
        <RouterContent />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}
